<!-- ----------------------------------------------------------------------- -->
<!--
name    : APP INVOICE LIST

type    : view

uses    : header-list
          main-container
          table-status
          header-view

route   : /invoices (default child route)
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div class="app-invoice-list" v-if="invoices">
    <header-list title="Invoices" />
    <main-container>
      <div>
        <v-card>
          <v-card-title>
            <v-btn
              height="28"
              class="button-primary"
              @click="filterdialog = true">
              <span>Filter</span>
              <v-icon right small>mdi-filter</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="QuickSearch"
              single-line
              hide-details></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="filteredInvoices"
            class="elevation-1"
            sort-by="number"
            sort-desc
            :search="search"
            :loading="busy">
            <template v-slot:item="props">
              <tr @click="viewInvoice(props.item)">
                <td>INV-{{ props.item.number.toString().padStart(5, '0') }}</td>
                <td>{{ props.item.client_name }}</td>
                <td>{{ formatCurrency(props.item.amount, currencySymbol) }}</td>
                <td>
                  {{ formatCurrency(props.item.balance, currencySymbol) }}
                </td>
                <td class="text-center">
                  <table-status :status="props.item.status" />
                </td>
              </tr>
            </template>
            <template v-slot:no-data>
              There are no available invoices.
            </template>
          </v-data-table>
        </v-card>
      </div>
      <v-dialog v-model="filterdialog" persistent max-width="600px">
        <v-card color="grey lighten-4" min-width="350px" flat>
          <header-view title="Filter Invoices" dialog />
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    label="Client Name Contains"
                    v-model="client_name"
                    hint=""
                    required></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <div id="invoice-status-filter">
                    <v-select
                      v-model="status"
                      :items="filterItems"
                      filled
                      label="Status"></v-select>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="button-primary" flat @click="filterdialog = false"
              >Apply</v-btn
            >
            <v-btn class="button-secondary" flat @click="clearFilter()"
              >Clear</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </main-container>
  </div>
</template>

<script>
  // components
  import Localization from '@/mixins/localization';
  import HeaderList from '@/components/header-list';
  import MainContainer from '@/components/main-container';
  import TableStatus from '@/components/table-status';
  import HeaderView from '@/components/header-view';

  // services
  import Invoices from '@/services/Invoices.service.js';

  export default {
    name: 'AppInvoiceList',
    components: {
      'header-list': HeaderList,
      'main-container': MainContainer,
      'table-status': TableStatus,
      'header-view': HeaderView,
    },
    mixins: [Localization],
    props: {},
    data() {
      return {
        filterdialog: false,
        client_name: '',
        status: '',
        filterItems: [
          {
            text: 'Show All',
            value: '',
          },
          {
            text: 'Open',
            value: 'open',
          },
          {
            text: 'Paid',
            value: 'paid',
          },
        ],
        search: '',
        busy: false,
        headers: [
          {
            text: 'Invoice ID',
            value: 'number',
          },
          {
            text: 'Client',
            value: 'client_name',
          },
          {
            text: 'Amount',
            value: 'amount',
          },
          {
            text: 'Balance',
            value: 'balance',
          },
          {
            text: 'Status',
            value: 'status',
            align: 'center',
          },
        ],
        invoices: null,
      };
    },
    computed: {
      currencySymbol() {
        var symbol = this.$auth.tenantProfile.currency_symbol
          ? this.$auth.tenantProfile.currency_symbol
          : '$';
        return symbol;
      },
      // a computed getter
      filteredInvoices: function () {
        return this.invoices.filter(
          function (invoice) {
            let pass = true;
            if (
              this.client_name &&
              !invoice.client_name
                .toLowerCase()
                .includes(this.client_name.toLowerCase())
            ) {
              pass = false;
            }
            if (this.status && invoice.status != this.status) {
              pass = false;
            }
            return pass;
          }.bind(this)
        );
      },
    },
    watch: {},
    async created() {
      await this.reloadInvoices();
    },
    methods: {
      viewInvoice: function (invoice) {
        this.$router.push({
          name: 'InvoiceView',
          params: {
            uuid: invoice.uuid,
          },
        });
      },
      async reloadInvoices() {
        this.busy = true;

        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        var invoices = await Invoices.getInvoicesByTenant(
          this.$auth.userProfile.tenant_uuid,
          {},
          accessToken
        );

        if (invoices) {
          this.invoices = invoices;
          this.busy = false;
        } else {
          console.log('failed to load invoices!');
          this.busy = false;
        }
      },
      clearFilter: function () {
        this.client_name = '';
        this.status = '';
        this.filterdialog = false;
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
  #filter-select {
    height: 60px;
    width: 100px;
    margin: auto;
  }
</style>
